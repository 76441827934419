// ProjectModal.js

import React, { useState, useEffect } from 'react';
import './ProjectModal.css';
import NewsList from './NewsList'; // Import NewsList
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import axios from 'axios'; // Import axios

function ProjectModal({ project, onClose }) {
  const [showCROWDNEWS, setShowCROWDNEWS] = useState(false); // Updated state
  const [shareSuccess, setShareSuccess] = useState(false);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  // State to hold preloaded CROWDNEWS data
  const [cyratorNews, setCyratorNews] = useState(null);
  const [loadingCyratorNews, setLoadingCyratorNews] = useState(true);

  useEffect(() => {
    // Prevent body scroll when the modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Restore body scroll when the modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    // Preload CROWDNEWS data when the modal opens, if the project has a cyratorid
    if (project.cyratorid) {
      const preloadCyratorNews = async () => {
        try {
          setLoadingCyratorNews(true); // Start loading
          const response = await axios.get(
            `/api/fetchCyratorNews?projectId=${project.cyratorid}`
          );
          if (response.headers['content-type']?.includes('application/json')) {
            setCyratorNews(response.data); // Store preloaded news in state
          } else {
            throw new Error('Invalid response format');
          }
        } catch (error) {
          console.error('Error preloading CROWDNEWS:', error);
        } finally {
          setLoadingCyratorNews(false); // Stop loading
        }
      };

      preloadCyratorNews();
    }
  }, [project.cyratorid]);

  const handleToggleInfo = () => {
    setShowCROWDNEWS(false); // Ensure CROWDNEWS is hidden
  };

  const handleToggleCROWDNEWS = () => {
    setShowCROWDNEWS(true);
  };

  const handleShare = async () => {
    const url = `${window.location.origin}?project=${encodeURIComponent(
      project.name
    )}`;
    const shareData = {
      title: project.name,
      text: `Check out this project: ${project.name}`,
      url: url,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(url);
        setShareSuccess(true);
        setTimeout(() => setShareSuccess(false), 3000);
      }
    } catch (err) {
      console.error('Sharing failed', err);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) return;

    const swipeDistance = touchStartX - touchEndX;

    // Set a threshold for swipe detection (e.g., 50px)
    const swipeThreshold = 100;

    if (swipeDistance > swipeThreshold) {
      // Swipe left to move to the next tab
      if (!showCROWDNEWS) {
        handleToggleCROWDNEWS(); // Go from Info to CROWDNEWS
      }
    } else if (swipeDistance < -swipeThreshold) {
      // Swipe right to move to the previous tab
      if (showCROWDNEWS) {
        handleToggleInfo(); // Go from CROWDNEWS to Info
      }
    }

    setTouchStartX(null);
    setTouchEndX(null);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        style={{
          backgroundImage: project.backgroundImage
            ? `url(${project.backgroundImage})`
            : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        onClick={stopPropagation}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <div className="modal-header">
          <img
            src={project.logo}
            alt={`${project.name} logo`}
            className="project-logo"
          />
          <h3
            className={project.backgroundImage ? 'project-title-with-bg' : ''}
          >
            {project.name}
          </h3>
        </div>

        {shareSuccess && (
          <div className="share-success">Link copied to clipboard!</div>
        )}

        <div className="tab-buttons">
          <button
            onClick={handleToggleInfo}
            className={`tab-button ${!showCROWDNEWS ? 'active' : ''}`}
          >
            Info
          </button>

          {/* CROWDNEWS button only shown if cyratorid exists */}
          {project.cyratorid && (
            <button
              onClick={handleToggleCROWDNEWS}
              className={`tab-button ${showCROWDNEWS ? 'active' : ''}`}
            >
              CROWDNEWS
            </button>
          )}

          <button onClick={handleShare} className="share-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="White"
                d="M18 16.08c-.76 0-1.44.3-1.96.77l-7.13-4.07c.05-.23.09-.46.09-.7 0-.24-.03-.47-.09-.7l7.12-4.06c.53.5 1.23.8 2 .8 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.03.47.09.7l-7.12 4.06C6.97 9.47 6.27 9.17 5.5 9.17c-1.66 0-3 1.34-3 3s1.34 3 3 3c.77 0 1.47-.3 1.98-.78l7.13 4.08c-.06.23-.1.47-.1.72 0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3z"
              />
            </svg>
          </button>
        </div>

        <div className="content-container">
          <div className="content-box">
            {!showCROWDNEWS && documentToReactComponents(project.description)}
            {showCROWDNEWS && (
              <NewsList
                cyratorId={project.cyratorid}
                preloadedNews={cyratorNews} // Pass preloaded CROWDNEWS
                loading={loadingCyratorNews} // Pass loading state
              />
            )}
          </div>
        </div>

        <div className="modal-footer">
          <div className="social-icons">
            {project.webUrl && (
              <a
                href={project.webUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="web.png" alt="Web" className="social-icon" />
              </a>
            )}
            {project.xUrl && (
              <a
                href={project.xUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="x.png" alt="X" className="social-icon" />
              </a>
            )}
            {project.telegramUrl && (
              <a
                href={project.telegramUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="telegram.png" alt="Telegram" className="social-icon" />
              </a>
            )}
            {project.discordUrl && (
              <a
                href={project.discordUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="discord.png" alt="Discord" className="social-icon" />
              </a>
            )}
            {project.cyratorUrl && (
              <a
                href={project.cyratorUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="Cyrator.png" alt="Cyrator" className="social-icon" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
