import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { marked } from 'marked'; // Correct named import
import './NewsList.css'; // Import the CSS for styling

const NewsList = ({ cyratorId, preloadedNews = null, loading = true }) => {
  const [news, setNews] = useState(preloadedNews || []);
  const [isLoading, setIsLoading] = useState(loading);
  const [error, setError] = useState(null);

  useEffect(() => {
    // If preloadedNews is provided, skip fetching from the API
    if (preloadedNews) {
      setIsLoading(false);
      return;
    }

    // If no Cyrator ID is provided, set error
    if (!cyratorId) {
      setError('No Cyrator ID provided.');
      setIsLoading(false);
      return;
    }

    // Fetch Cyrator news if no preloaded data
    const fetchNews = async () => {
      try {
        console.log(`Fetching Cyrator news for project ID: ${cyratorId}`);

        // Calling the serverless function
        const response = await axios.get(`/api/fetchCyratorNews?projectId=${cyratorId}`);

        if (response.headers['content-type']?.includes('application/json')) {
          setNews(response.data);
          console.log('Cyrator news response:', response.data);
        } else {
          throw new Error('Invalid response format');
        }

        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching Cyrator news:', err);
        setError(`Failed to load Cyrator news: ${err.message}`);
        setIsLoading(false);
      }
    };

    fetchNews();
  }, [cyratorId, preloadedNews]);

  if (isLoading) {
    return <p>Loading Cyrator news...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (news.length === 0) {
    return <p>No news available for this project.</p>;
  }

  return (
    <ul className="news-list">
      {news.map((item) => (
        <NewsItem key={item._id} item={item} />
      ))}
    </ul>
  );
};

const NewsItem = ({ item }) => {
  // Helper function to extract the first bold Unicode part of the plainText if the title is empty
  const getFallbackTitle = (plainText) => {
    // Try to extract bold text first
    const boldTextMatch = plainText.match(/^[\u{1D400}-\u{1D7FF}\s;!]+/u); // Matches bold Unicode characters and punctuation

    if (boldTextMatch) {
      return boldTextMatch[0].trim(); // Return the first matched bold part with punctuation
    }

    // If no bold text, extract the first part before the first double newline (\n\n)
    const firstPart = plainText.split('\n\n')[0];
    return firstPart.trim(); // Return the first paragraph before the first \n\n
  };

  // Use the title if available, otherwise fallback to extracting the first bold Unicode text or the first part of the plainText
  const title = item.title || getFallbackTitle(item.plainText);

  // If no title or bold text is found, provide a default
  const displayTitle = title || 'No title available';

  const renderMarkdown = (text) => {
    // Convert markdown to HTML using marked
    const rawHtml = marked(text);

    // If the title was extracted from the plainText, remove it from the HTML content
    if (!item.title) {
      const titleIndex = rawHtml.indexOf(displayTitle);
      if (titleIndex !== -1) {
        return rawHtml.replace(displayTitle, '').trim(); // Remove the title from the rendered HTML
      }
    }

    return rawHtml; // Return the original HTML if no title needs to be removed
  };

  return (
    <li className="news-item">
      {/* Render title (either actual title or extracted text from plainText) inside an h2 */}
      <h2>
        {displayTitle}
      </h2>
      
      <div
        className="markdown-content"
        dangerouslySetInnerHTML={{ __html: renderMarkdown(item.text) }} // Use 'text' for the markdown content
      />

      <p><strong>Updated:</strong> {new Date(item.updatedAt).toLocaleDateString()}</p>
      {item.user && (
        <p><strong>Author:</strong> {item.user.name}</p>
      )}

      {/* "View on CROWDNEWS" link */}
      <p>
        <a
          href={`https://crowd.news/post/${item._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="view-on-cyrator"
        >
          View on CROWDNEWS
        </a>
      </p>
    </li>
  );
};

export default NewsList;
