import client from './contentfulClient'; // Reuse the existing client

export const fetchProjects = async () => {
  const entries = await client.getEntries({
    content_type: 'projects', // Ensure this matches the Content Type ID in Contentful
  });

  return entries.items.map((item) => ({
    name: item.fields.name,
    category: item.fields.category,
    logo: item.fields.logo.fields.file.url, // Access the URL of the uploaded logo image
    description: item.fields.description, // This will be Rich Text, we'll need to render it correctly
    webUrl: item.fields.webUrl,
    xUrl: item.fields.xUrl,
    telegramUrl: item.fields.telegramUrl,
    discordUrl: item.fields.discordUrl,
    cyratorUrl: item.fields.cyrator, // Cyrator URL
    cyratorid: item.fields.cyratorid, // Add this line to fetch the Cyrator ID from Contentful
    backgroundImage: item.fields.backgroundImage?.fields.file.url, // Optional field
  }));
};
