// App.js

import React, { useState, useEffect } from 'react';
import './App.css';
import ProjectModal from './ProjectModal';
import ErrorBoundary from './ErrorBoundary';
import { fetchProjects } from './fetchProjects';

function App() {
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const getProjects = async () => {
      const projectData = await fetchProjects();
      setProjects(projectData);

      const categoryCount = projectData.reduce((acc, project) => {
        acc[project.category] = (acc[project.category] || 0) + 1;
        return acc;
      }, {});

      const sortedCategories = Object.keys(categoryCount).sort(
        (a, b) => categoryCount[b] - categoryCount[a]
      );
      setCategories(sortedCategories);

      const urlParams = new URLSearchParams(window.location.search);
      const projectName = urlParams.get('project');
      if (projectName) {
        const projectToOpen = projectData.find(
          (p) => p.name === projectName
        );
        if (projectToOpen) {
          setSelectedProject(projectToOpen);
          setModalVisible(true);
        }
      }
    };

    getProjects();
  }, []);

  const handleLogoClick = (project) => {
    setSelectedProject(project);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedProject(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="App">
      <div className="ecosystem-bar">
        <img
          src="Coinwebecosystem.png"
          alt="Coinweb Ecosystem"
          className="ecosystem-image"
        />
      </div>
      <div className="categories">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-box"
          />
        </div>
        {categories.map((category, index) => {
          // Filter projects for this category based on search query
          const filteredProjects = projects.filter(
            (project) =>
              project.category === category &&
              project.name.toLowerCase().includes(searchQuery.toLowerCase())
          );

          // Hide categories with no projects
          if (filteredProjects.length === 0) return null;

          return (
            <div key={index} className="category">
              <button className="category-button">{category}</button>
              <div className="logos">
                {filteredProjects.map((project, index) => (
                  <div
                    key={index}
                    className="project-item"
                    onClick={() => handleLogoClick(project)}
                  >
                    <img
                      src={project.logo}
                      alt={`${project.name} logo`}
                    />
                    <span>{project.name}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      {selectedProject && (
        <ErrorBoundary>
          <ProjectModal project={selectedProject} onClose={closeModal} />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default App;
